.animated {
  -webkit-animation-duration: .5s;
  animation-duration: .5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

.react-notification-root>div {
  margin: 1.5rem;
  position: fixed;
  z-index: 8000;

  display: flex;
  flex-flow: column;
  flex-direction: column-reverse;

  .n-parent {
    margin-bottom: 0.5rem;
  }
}

.notification-container {
  &-mobile-top {
    right: 0;
    left: 0;
    top: 0;
  }

  &-mobile-bottom {
    right: 0;
    left: 0;
    bottom: 0;
  }

  &-top-left {
    left: 0;
    top: 0;
  }

  &-top-right {
    right: 0;
    top: 0;
  }

  &-bottom-left {
    left: 0;
    bottom: 0;
  }

  &-bottom-right {
    right: 0;
    bottom: 0;
  }

  &-top-center {
    top: 0;
  }

  &-bottom-center {
    bottom: 0;
  }
}
